import React, {Component} from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Lottie from 'react-lottie';
import AlfieAmazed from '../assets/animation/Dog_Sitting-Talking_Amazed';
import BreadCrumbs from "../components/bread-crumbs";
import "./privacy-policy.scss";

const breadCrumbsList  = [

    {
        title: "Home",
        link: "/",
    }

]

class PrivacyPolicy extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        window.analytics.page("Privacy Policy");
    }

    render() {
        return (
            <Layout location={this.props.location}>
                <SEO title="Privacy Policy"/>
                <BreadCrumbs currentPage={"Privacy Policy"} breadCrumbsList={breadCrumbsList}/>
                <div className="poli-wave-container">
                    <div className="poli-content-wrap">
                        <div className="privacy-header">
                            <h2 className="blackHeading">Privacy Policy</h2>
                        </div>
                        <div className="manifesto-animation">
                            <Lottie options={{
                                loop: true,
                                autoplay: true,
                                animationData: AlfieAmazed
                            }}
                                    height={430}
                                    width={460}
                            />
                        </div>
                        <div className="privacy-list">
                            <div className="single-privacy">
                                <div className="festo-content">
                                    <p className="festo-parah">Your privacy is
                                        important to us. It is Confidant
                                        Health's
                                        policy to respect your privacy regarding
                                        any information we may collect from you
                                        across our website, mobile app, and
                                        other sites we own and operate.</p>
                                    <h2 className="festo-head">1. Information we
                                        collect</h2>
                                    <p className="festo-parah">
                                        <span
                                            className="dark-head">Log data</span>
                                        When you visit our website, our servers
                                        may automatically log the standard data
                                        provided by your web browser. It may
                                        include your computer’s Internet
                                        Protocol (IP) address, your browser type
                                        and version, the pages you visit, the
                                        time and date of your visit, the time
                                        spent on each page, and other details.
                                        <span
                                            className="dark-head">Device data</span>
                                        We may also collect data about the
                                        device you’re using to access our
                                        website. This data may include the
                                        device type, operating system, unique
                                        device identifiers, device settings, and
                                        geo-location data. What we collect can
                                        depend on the individual settings of
                                        your device and software. We recommend
                                        checking the policies of your device
                                        manufacturer or software provider to
                                        learn what information they make
                                        available to us.

                                        <span>Personal information</span>
                                        We may ask for personal information,
                                        such as your:
                                    </p>
                                    <h2 className="festo-head">2. Legal bases
                                        for processing</h2>
                                    <p className="festo-parah">We will process
                                        your personal information lawfully,
                                        fairly and in a transparent manner. We
                                        collect and process information about
                                        you only where we have legal bases for
                                        doing so.
                                        These legal bases depend on the services
                                        you use and how you use them, meaning we
                                        collect and use your information only
                                        where:
                                    </p>
                                    <ul className="policy-ul">
                                        <li>it’s necessary for the performance
                                            of a contract to which you are a
                                            party or to take steps at your
                                            request before entering into such a
                                            contract (for example, when we
                                            provide a service you request from
                                            us);
                                        </li>
                                        <li>it satisfies a legitimate interest
                                            (which is not overridden by your
                                            data protection interests), such as
                                            for research and development, to
                                            market and promote our services, and
                                            to protect our legal rights and
                                            interests;
                                        </li>
                                        <li>you give us consent to do so for a
                                            specific purpose (for example, you
                                            might consent to us sending you our
                                            newsletter);
                                        </li>
                                        <li>we need to process your data to
                                            comply with a legal obligation
                                        </li>
                                    </ul>
                                    <p className="festo-parah">Where you consent
                                        to our use of information about you for
                                        a specific purpose, you have the right
                                        to change your mind at any time (but
                                        this will not affect any processing that
                                        has already taken place).</p>
                                    <p className="festo-parah">We don’t keep
                                        personal information for longer than is
                                        necessary. While we retain this
                                        information, we will protect it within
                                        commercially acceptable means to prevent
                                        loss and theft, as well as unauthorized
                                        access, disclosure, copying, use or
                                        modification. That said, we advise that
                                        no method of electronic transmission or
                                        storage is 100% secure and cannot
                                        guarantee absolute data security. If
                                        necessary, we may retain your personal
                                        information for our compliance with a
                                        legal obligation or in order to protect
                                        your vital interests or the vital
                                        interests of another natural person.</p>
                                    <h2 className="festo-head">3. Collection and
                                        use of information</h2>
                                    <p className="festo-parah">We may collect,
                                        hold, use and disclose information for
                                        the following purposes and personal
                                        information will not be further
                                        processed in a manner that is
                                        incompatible with these purposes:</p>
                                    <ul className="policy-ul">
                                        <li>to enable you to customize or
                                            personalize your experience of our
                                            website;
                                        </li>
                                        <li>to enable you to access and use our
                                            website, associated applications and
                                            associated social media platforms;
                                        </li>
                                        <li>to contact and communicate with
                                            you;
                                        </li>
                                        <li>for internal record keeping and
                                            administrative purposes;
                                        </li>
                                        <li>for analytics, market research and
                                            business development, including to
                                            operate and improve our website,
                                            associated applications and
                                            associated social media platforms;
                                        </li>
                                        <li>to run competitions and/or offer
                                            additional benefits to you;
                                        </li>
                                        <li>for advertising and marketing,
                                            including to send you promotional
                                            information about our products and
                                            services and information about third
                                            parties that we consider may be of
                                            interest to you; and to comply with
                                            our legal obligations and resolve
                                            any disputes that we may have.
                                        </li>
                                    </ul>
                                    <h2 className="festo-head">4. Disclosure of
                                        personal information to third
                                        parties</h2>
                                    <p className="festo-parah">We may disclose
                                        personal information to:</p>
                                    <ul className="policy-ul">
                                        <li>third party service providers for
                                            the purpose of enabling them to
                                            provide their services, including
                                            (without limitation) IT service
                                            providers, data storage, hosting and
                                            server providers, ad networks,
                                            analytics, error loggers, debt
                                            collectors, maintenance or
                                            problem-solving providers, marketing
                                            or advertising providers,
                                            professional advisors and payment
                                            systems operators;
                                        </li>
                                        <li>and our employees, contractors
                                            and/or related entities.
                                        </li>
                                    </ul>

                                    <h2 className="festo-head">5. International
                                        transfers of personal information</h2>
                                    <p className="festo-parah">The personal
                                        information we collect is stored and
                                        processed in United States, or where we
                                        or our partners, affiliates and
                                        third-party providers maintain
                                        facilities. By providing us with your
                                        personal information, you consent to the
                                        disclosure to these overseas third
                                        parties.

                                        We will ensure that any transfer of
                                        personal information from countries in
                                        the European Economic Area (EEA) to
                                        countries outside the EEA will be
                                        protected by appropriate safeguards, for
                                        example by using standard data
                                        protection clauses approved by the
                                        European Commission, or the use of
                                        binding corporate rules or other legally
                                        accepted means.

                                        Where we transfer personal information
                                        from a non-EEA country to another
                                        country, you acknowledge that third
                                        parties in other jurisdictions may not
                                        be subject to similar data protection
                                        laws to the ones in our jurisdiction.
                                        There are risks if any such third party
                                        engages in any act or practice that
                                        would contravene the data privacy laws
                                        in our jurisdiction and this might mean
                                        that you will not be able to seek
                                        redress under our jurisdiction’s privacy
                                        laws.</p>
                                    <h2 className="festo-head">6. Your rights
                                        and controlling your personal
                                        information</h2>
                                    <p className="festo-parah">Choice and
                                        consent : By providing personal
                                        information to us, you consent to us
                                        collecting, holding, using and
                                        disclosing your personal information in
                                        accordance with this privacy policy. If
                                        you are under 16 years of age, you must
                                        have, and warrant to the extent
                                        permitted by law to us, that you have
                                        your parent or legal guardian’s
                                        permission to access and use the website
                                        and they (your parents or guardian) have
                                        consented to you providing us with your
                                        personal information. You do not have to
                                        provide personal information to us,
                                        however, if you do not, it may affect
                                        your use of this website or the products
                                        and/or services offered on or through
                                        it.

                                        Information from third parties : If we
                                        receive personal information about you
                                        from a third party, we will protect it
                                        as set out in this privacy policy. If
                                        you are a third party providing personal
                                        information about somebody else, you
                                        represent and warrant that you have such
                                        person’s consent to provide the personal
                                        information to us.

                                        Restrict : You may choose to restrict
                                        the collection or use of your personal
                                        information. If you have previously
                                        agreed to us using your personal
                                        information for direct marketing
                                        purposes, you may change your mind at
                                        any time by contacting us using the
                                        details below. If you ask us to restrict
                                        or limit how we process your personal
                                        information, we will let you know how
                                        the restriction affects your use of our
                                        website or products and services.

                                        Access and data portability : You may
                                        request details of the personal
                                        information that we hold about you. You
                                        may request a copy of the personal
                                        information we hold about you. Where
                                        possible, we will provide this
                                        information in CSV format or other
                                        easily readable machine format. You may
                                        request that we erase the personal
                                        information we hold about you at any
                                        time. You may also request that we
                                        transfer this personal information to
                                        another third party.

                                        Correction : If you believe that any
                                        information we hold about you is
                                        inaccurate, out of date, incomplete,
                                        irrelevant or misleading, please contact
                                        us using the details below. We will take
                                        reasonable steps to correct any
                                        information found to be inaccurate,
                                        incomplete, misleading or out of date.

                                        Notification of data breaches : We will
                                        comply laws applicable to us in respect
                                        of any data breach.

                                        Complaints : If you believe that we have
                                        breached a relevant data protection law
                                        and wish to make a complaint, please
                                        contact us using the details below and
                                        provide us with full details of the
                                        alleged breach. We will promptly
                                        investigate your complaint and respond
                                        to you, in writing, setting out the
                                        outcome of our investigation and the
                                        steps we will take to deal with your
                                        complaint. You also have the right to
                                        contact a regulatory body or data
                                        protection authority in relation to your
                                        complaint.

                                        Unsubscribe : To unsubscribe from our
                                        e-mail database or opt-out of
                                        communications (including marketing
                                        communications), please contact us using
                                        the details below or opt-out using the
                                        opt-out facilities provided in the
                                        communication.
                                    </p>
                                    <h2 className="festo-head">7. Cookies</h2>
                                    <p className="festo-parah">We use “cookies”
                                        to collect information about you and
                                        your activity across our site. A cookie
                                        is a small piece of data that our
                                        website stores on your computer, and
                                        accesses each time you visit, so we can
                                        understand how you use our site. This
                                        helps us serve you content based on
                                        preferences you have specified. Please
                                        refer to our Cookie Policy for more
                                        information.</p>
                                    <h2 className="festo-head">8. Business
                                        transfers</h2>
                                    <p className="festo-parah">
                                        If we or our assets are acquired, or in
                                        the unlikely event that we go out of
                                        business or enter bankruptcy, we would
                                        include data among the assets
                                        transferred to any parties who acquire
                                        us. You acknowledge that such transfers
                                        may occur, and that any parties who
                                        acquire us may continue to use your
                                        personal information according to this
                                        policy.
                                    </p>
                                    <h2 className="festo-head">9. Limits of our
                                        policy</h2>
                                    <p className="festo-parah">Our website may
                                        link to external sites that are not
                                        operated by us. Please be aware that we
                                        have no control over the content and
                                        policies of those sites, and cannot
                                        accept responsibility or liability for
                                        their respective privacy practices.</p>
                                    <h2 className="festo-head">10. Changes to
                                        this policy</h2>
                                    <p className="festo-parah">At our
                                        discretion, we may change our privacy
                                        policy to reflect current acceptable
                                        practices. We will take reasonable steps
                                        to let users know about changes via our
                                        website. Your continued use of this site
                                        after any changes to this policy will be
                                        regarded as acceptance of our practices
                                        around privacy and personal information.

                                        If we make a significant change to this
                                        privacy policy, for example changing a
                                        lawful basis on which we process your
                                        personal information, we will ask you to
                                        re-consent to the amended privacy
                                        policy.
                                    </p>
                                    <div className="p-contact-info">
                                        <div>
                                            <p>Confidant Health Data
                                                Controller</p>
                                            <p>Jon Read</p>
                                            <p>jon@confidanthealth.com</p>
                                        </div>
                                        <div className="contact-p-two">
                                            <p>Confidant Health Data Protection
                                                Officer</p>
                                            <p>Jon Read</p>
                                            <p>jon@confidanthealth.com</p>
                                        </div>
                                    </div>
                                    <p className="festo-parah">This policy is
                                        effective as of 7 August 2019.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default PrivacyPolicy
